import React from 'react'
import Link from 'gatsby-link'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


import styles from './feedback.module.scss'

class Feedback extends React.Component {
  constructor(props) {
    super(props);
   // Bind the this context to the handler function
        // Set some state
        this.state = {
       positive: false,
       negative: false,
       formSent: false
     }
  }

  showAll = () => {
    this.setState({
      positive: true
    });
    if(process.env.NODE_ENV !== 'development') {
      ga('send', 'event', 'Feedback', 'Helpful', this.props.post.title);
    }
    
  }
  showNone = () => {
    this.setState({
      negative: true
    });
    if(process.env.NODE_ENV !== 'development') {
      ga('send', 'event', 'Feedback', 'Not helpful', this.props.post.title);
    }

  }

  cancelNegative = () => {
    this.setState({
      negative: false
    });
  }

  formSend = () => {
    this.setState({
      formSent: true
    });
  }

  render() {

    var panelClass = '';
    var errorMessage = "Thank you!"



    if (this.state.positive) {
      panelClass = styles.thumbsUp
      errorMessage = "Thank you!"
    }

    if (this.state.negative) {
      panelClass = styles.thumbsDown
      errorMessage = "Thanks for your feedback"
    }

    if (this.state.formSent) {
      panelClass = [styles.thumbsDown, styles.formSent].join(' ')
      errorMessage = "Thanks for your feedback"
    }

    const articleInformation = this.props.post;
    var resultsLink = '';
    if(articleInformation.productReference != null) {
      resultsLink = <Link className={styles.seeAllTopicButton} to={`/${articleInformation.productReference.slug}/${articleInformation.articleCategoryTopic.slug}/`}>See all {articleInformation.articleCategoryTopic.title} results</Link>
    }

    return (
  <aside className={[styles.feedback, panelClass].join(' ')}>
  {resultsLink}
    <p className={styles.feedbackLabel}>Was this helpful?</p>

    <a className={[styles.feedbackButton, styles.positive].join(' ')} title="Solved my problem" onClick={this.showAll}>
      <svg width="44" height="48" xmlns="http://www.w3.org/2000/svg"><g><path d="M10 42H6c-2.2 0-4-1.6-4-3.6V23.6c0-2 1.8-3.6 4-3.6h4c2.2 0 4 1.6 4 3.6v14.8c0 2-1.8 3.6-4 3.6zM20 46h12c6 0 10-4 10-10V23a3 3 0 0 0-3-3h-5M10 20c2 0 10-2 10-9.5V7c0-3 2-5 5-5s5 2 5 5v9c0 2 2 4 4 4"/></g></svg>
    </a>
    <a className={[styles.feedbackButton, styles.negative].join(' ')} title="Not helpful" onClick={this.showNone}>
      <svg width="44" height="48" xmlns="http://www.w3.org/2000/svg"><g><path d="M10 42H6c-2.2 0-4-1.6-4-3.6V23.6c0-2 1.8-3.6 4-3.6h4c2.2 0 4 1.6 4 3.6v14.8c0 2-1.8 3.6-4 3.6zM20 46h12c6 0 10-4 10-10V23a3 3 0 0 0-3-3h-5M10 20c2 0 10-2 10-9.5V7c0-3 2-5 5-5s5 2 5 5v9c0 2 2 4 4 4"/></g></svg>
    </a>
    <div className={styles.feedbackResponse}>{errorMessage}</div>

    <form>
    <hr />
    <p>We're sorry to hear that.</p>
    <p>If you still require assistance, or have any feedback, please leave a message on the <OutboundLink href="https://community.youview.com">YouView Community</OutboundLink></p>
</form>
  </aside>
    )
  }
}

export default Feedback
